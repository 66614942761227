<template>
	<div class="tabs">
		<h3><span>业务应用场景</span></h3>
		<div class="tabs_box">
			<ul class="tab_inner">
				<li @click="settabindex(0)" :class="[tabs==0?'active':'']">等级保护</li>
				<li @click="settabindex(1)"  :class="[tabs==1?'active':'']">风险评估</li>
				<li @click="settabindex(2)"  :class="[tabs==2?'active':'']">安全服务</li>
				
			</ul>
			<ul class="show_img_box">
				<li v-if="tabs==0">
					<img  src="@/assets/images/scene/a01.jpg">
					<img  src="@/assets/images/scene/a02.jpg">
					<img  src="@/assets/images/scene/a03.jpg">
					<img  src="@/assets/images/scene/a04.png">
					<img  src="@/assets/images/scene/a05.png">
					<img  src="@/assets/images/scene/a06.png">
					<img  src="@/assets/images/scene/a07.png">
					<img  src="@/assets/images/scene/a08.png">
					<img  src="@/assets/images/scene/a09.png">
					<img  src="@/assets/images/scene/a10.png">
					<img  src="@/assets/images/scene/a11.png">
					<img  src="@/assets/images/scene/a12.png">
					<img  src="@/assets/images/scene/a13.jpg">
					<img  src="@/assets/images/scene/a14.jpg">
					<img  src="@/assets/images/scene/a15.jpg">
					<img  src="@/assets/images/scene/a16.jpg">
					<img  src="@/assets/images/scene/a17.jpg">
					<img  src="@/assets/images/scene/a18.jpg">
					<img  src="@/assets/images/scene/a19.jpg">
					<img  src="@/assets/images/scene/a20.jpg">
					<img  src="@/assets/images/scene/a21.jpg">
					<img  src="@/assets/images/scene/a22.jpg">
					<img  src="@/assets/images/scene/a23.jpg">
					<img  src="@/assets/images/scene/a24.jpg">
					<img  src="@/assets/images/scene/a25.jpg">
					<img  src="@/assets/images/scene/a26.jpg">
					<img  src="@/assets/images/scene/a27.jpg">
					<img  src="@/assets/images/scene/a28.jpg">
					<img  src="@/assets/images/scene/a29.jpg">
					<img  src="@/assets/images/scene/a30.jpg">
					<img  src="@/assets/images/scene/a31.jpg">
					<img  src="@/assets/images/scene/a32.jpg">
					<img  src="@/assets/images/scene/a33.jpg">
					<img  src="@/assets/images/scene/a34.jpg">
					<img  src="@/assets/images/scene/a35.jpg">
					<img  src="@/assets/images/scene/a36.jpg">
					<img  src="@/assets/images/scene/a37.jpg">
				</li>
				<li  v-if="tabs==1">
				  <img  src="@/assets/images/scene/b01.jpg">
				  <img  src="@/assets/images/scene/b02.jpg">
				  <img  src="@/assets/images/scene/b03.png">
				  <img  src="@/assets/images/scene/b04.png">
				  <img  src="@/assets/images/scene/b05.png">
				  <img  src="@/assets/images/scene/b06.jpg">
				  <img  src="@/assets/images/scene/b07.jpg">
				  <img  src="@/assets/images/scene/b08.jpg">
				  <img  src="@/assets/images/scene/b09.jpg">
				  <img  src="@/assets/images/scene/b10.jpg">
				  <img  src="@/assets/images/scene/b11.jpg">
				  <img  src="@/assets/images/scene/b12.jpg">
				  <img  src="@/assets/images/scene/b13.jpg">
				  <img  src="@/assets/images/scene/b14.jpg">
				  <img  src="@/assets/images/scene/b15.jpg">
				  <img  src="@/assets/images/scene/b16.jpg">
				  <img  src="@/assets/images/scene/b17.jpg">
				  <img  src="@/assets/images/scene/b18.jpg">
				</li>
				<li  v-if="tabs==2">
					<img  src="@/assets/images/scene/c01.png">
					<img  src="@/assets/images/scene/c02.png">
					<img  src="@/assets/images/scene/c03.png">
					<img  src="@/assets/images/scene/c04.jpg">
					<img  src="@/assets/images/scene/c05.jpg">
					<img  src="@/assets/images/scene/c06.jpg">
					<img  src="@/assets/images/scene/c07.jpg">
					<img  src="@/assets/images/scene/c08.jpg">
					<img  src="@/assets/images/scene/c09.jpg">
					<img  src="@/assets/images/scene/c10.jpg">
					<img  src="@/assets/images/scene/c11.jpg">
					<img  src="@/assets/images/scene/c12.jpg">
					<img  src="@/assets/images/scene/c13.jpg">
					<img  src="@/assets/images/scene/c14.jpg">
					<img  src="@/assets/images/scene/c15.jpg">
					<img  src="@/assets/images/scene/c16.jpg">
					<img  src="@/assets/images/scene/c17.jpg">
					<img  src="@/assets/images/scene/c18.jpg">
					<img  src="@/assets/images/scene/c19.jpg">
					<img  src="@/assets/images/scene/c20.jpg">
					<img  src="@/assets/images/scene/c21.jpg">
					<img  src="@/assets/images/scene/c22.jpg">
					<img  src="@/assets/images/scene/c23.png">
				</li>
			</ul>
		</div>
		<div class="tui">
				<h6><span>推荐产品</span></h6>
				<ul class="recommandList">
		          <li>
		            <router-link to="/details" target="_blank" class="prodLink">
		            <div class="recommandLeft">
		              <img src="@/assets/images/b01.jpg" alt="">
		            </div>
		            <div class="recommandRight">
		              <h5 id="prodName">网络安全等级保护测评服务</h5>
		              <!-- <p>网络安全等级保护测评服务</p>
		              <i>￥</i><span>40000</span> <b style="color:#888">/年起</b> -->
		            </div>
		            </router-link>
		          </li>
		          <li>
		            <router-link to="/icpedi" target="_blank" class="prodLink">
		            <div class="recommandLeft">
		              <img src="@/assets/images/icpedi/2.png" alt="">
		            </div>
		            <div class="recommandRight">
		              <h5 id="prodName">ICP/EDI许可证第三方风险评估</h5>
		             <!--  <p>ICP/EDI许可证第三方风险评估</p>
		              <i>￥</i><span>0.00</span> -->
		            </div>
		            </router-link>
		          </li>
		          <li>
		            <router-link to="/dengbaohegui" target="_blank" class="prodLink">
		            <div class="recommandLeft">
		              <img src="@/assets/images/dengbaohegui/3.png" alt="">
		            </div>
		            <div class="recommandRight">
		              <h5 id="prodName">等保合规套餐</h5>
		            <!--   <p>等保合规套餐</p> 
		              <i>￥</i><span>0.00</span> -->
		            </div>
		            </router-link>
		          </li>
		          <li>
		            <router-link to="/yunsafe" target="_blank" class="prodLink">
		            <div class="recommandLeft">
		              <img src="@/assets/images/yunsafe/222.jpg" alt="">
		            </div>
		            <div class="recommandRight">
		              <h5 id="prodName">云上业务安全无忧保障计划初级套餐</h5>
		             <!--  <p>云上业务安全无忧保障计划初级套餐</p> 
		              <i>￥</i><span>0.00</span> -->
		            </div>
		            </router-link>
		          </li>
		        </ul>

		</div>


	</div>
 
</template>
<script>
  export default {
    data() {
      return {
        tabs: 0
      };
    },
    methods: {
      settabindex(val){
      this.tabs=val;
      }
    }
  };
</script>

<style type="text/css" scoped lang="less">
.tabs{

h3{
margin: 0px auto;
font-size: 28px;
text-shadow: none;
color: #333;
width:1200px ;
text-align: center;
padding: 30px 0;
background:#fff;
margin-top:20px;
}
h3 span{
	padding:5px;
	padding-bottom:8px;
	border-bottom:3px solid  #62a8ea;
}
.tabs_box{
 width:1200px;
 margin:0 auto;
 padding:0 10px;
 box-sizing:border-box;
 background: #fff;
}
.tab_inner{
	overflow: hidden;
	border-bottom:1px solid #f0f2f5;
	padding:10px 0;
	text-align:center;
}
.tab_inner li{
	display: inline-block;
	padding: 5px 10px;
    font-size: 16px;
    color:#292e33;
}
.tab_inner li:hover{
	color:#62a8ea;
	cursor: pointer;
}
.tab_inner li.active{
	color:#62a8ea;
}
.show_img_box{
	width:1000px;
	margin:0 auto;
}
.show_img_box li{
	text-align:center;
	min-height:250px;
}
.show_img_box li p{
	padding:30px 0;
	font-size:15px;
}
.show_img_box li img{
  width:300px;
  height:100px;
  color:#666;
  margin:10px 3px;
}
.show_img_box li img:hover{
	background-color: #fff;
	background-position-y: -240px;
    box-shadow: 0 2px 5px 1px #edeaea, 0 0 5px 1px #edeaea, 0 0 5px 1px #edeaea;
}
.tui {
	margin:20px auto;
	width:1200px;
}
.tui h6{
	text-align:center;
	font-size:24px;
	color:#333;
	padding:20px 0;
    background:#fff;
}
.tui h6 span{
	padding:5px;
	padding-bottom:8px;
	border-bottom:3px solid  #62a8ea;
}
.recommandList{
	 width: 100%;
	 margin:0 auto;
	 padding:0 10%;
    // padding:4px 20px;
    overflow: hidden;
    background:#fff;
    box-sizing: border-box;
    padding-bottom:30px;
}
.recommandList li{
   // display: flex;
    float:left;
    width:25%;
    margin-top: 25px;
    cursor: pointer;
}
.recommandLeft{
  width:90%;
  // height: 70px;
  margin:0 2%;
  background-color: skyblue;
  display: inline-block;
  float: left;
}
.recommandLeft>img{
	width:100%;
	// height:70px;
	// margin-right:3px;
}
.recommandRight{
	display: inline-block;
  width: 90%;
  padding: 0;
  height: 100%;
  padding-left:3px;
  box-sizing:border-box;
  float: left;
}
.recommandRight>h5 {
  font-size: 15px;
  color: #555;
  line-height:28px;
  padding-left:3px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
.recommandRight p {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    padding: 0;
    margin: 5px 0 0;
    overflow: hidden;
    line-height: 25px;
    margin-bottom:5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
 }
.recommandRight span {
    font-size: 12px;
    font-weight: 400;
    color: #FF6A00;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
}
.recommandRight>i{
    font-size: 12px;
    font-weight: 400;
    color: #FF6A00;
}




}
</style>